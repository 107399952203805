import React, { memo, useContext } from 'react';
import { string } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { AppContext } from '@thd-nucleus/app-render';
import { useParams } from '@thd-olt-component-react/router';
import { NoIndexNoFollowMetadata, Metadata } from '@thd-olt-component-react/metadata';
import { previewDataModel } from '../../contentful/dataModel/previewDataModel';
import { EducationalLayoutRenderer } from '../../contentful/templates/EducationalLayoutRenderer';
import { NavigationalLayoutRenderer } from '../../contentful/templates/NavigationalLayoutRenderer';
import { InspirationalLayoutRenderer } from '../../contentful/templates/InspirationalLayoutRenderer';
import { FlexibleLayoutRenderer } from '../../contentful/templates/FlexibleLayoutRenderer';

export const LayoutContainer = memo(({ ...props }) => {
  const { instance } = useContext(AppContext);
  let customerType = instance?.customer?.type;

  const { LayoutId, LayoutClass, TestPageId } = useParams();

  let layoutQueryName = '';
  let Renderer = ';';

  switch (LayoutClass) {
  case 'EducationalLayout':
    layoutQueryName = 'educationalPreview';
    Renderer = EducationalLayoutRenderer;
    break;
  case 'NavigationalLayout':
    layoutQueryName = 'navigationalPreview';
    Renderer = NavigationalLayoutRenderer;
    break;
  case 'InspirationalLayout':
    layoutQueryName = 'inspirationalPreview';
    Renderer = InspirationalLayoutRenderer;
    break;
  default:
    layoutQueryName = 'flexiblePreview';
    Renderer = FlexibleLayoutRenderer;
    break;
  }

  const { data, loading, error } = useDataModel(layoutQueryName, {
    variables: {
      id: LayoutId,
      layoutClass: LayoutClass,
    },
  });

  // useHelmet('landing-page', { data }, imagePreloader, [data]);

  if (!data || loading || error) {
    return null;
  }

  const fakeLayoutsData = {
    layouts: {
      title: 'Layout Preview',
      content: data?.layout
    }
  };

  return (
    <>
      <Metadata>
        <NoIndexNoFollowMetadata />
      </Metadata>
      <Renderer
        data={fakeLayoutsData}
        loading={loading}
        pageId={TestPageId || ''}
        storeId={props?.storeId}
        customerType={customerType}
        pageTypeComponents={{
          landingPageBreadcrumbs: null,
          landingPageMetadata: null
        }}
        isPreviewLink
      />
    </>
  );
});

LayoutContainer.dataModel = previewDataModel;

LayoutContainer.displayName = 'LayoutContainer';

LayoutContainer.defaultProps = {};

LayoutContainer.propTypes = {
  storeId: string.isRequired,
};